import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

export namespace Lookups {
  export async function getCategories(pagesize = -1, showDisabled = false) {
    return Axios.get(
      generateUrl(
        `${CategoriesUrls.fetchCategoriesUrl}/${store.state.shops.activeShop.id}`
      ),
      {
        params: {
          pagesize,
          showDisabled,
        },
        headers: await generateHeader(),
      }
    );
  }
  export async function getOptions(pagesize = -1, showDisabled = false) {
    return Axios.get(generateUrl(`product/options/set`), {
      params: {
        pagesize,
        showDisabled,
      },
      headers: await generateHeader(),
    });
  }
  export async function getExtras(page = -1, showDisabled = false) {
    return Axios.get(
      generateUrl(
        `${CategoriesUrls.fetchExtrasUrl}/${store.state.shops.activeShop.id}`
      ),
      {
        params: {
          page,
          showDisabled,
        },
        headers: await generateHeader(),
      }
    );
  }
  export async function fetchOrgTypes() {
    return Axios.get(generateUrl("organisations/types"), {
      headers: await generateHeader(),
    });
  }

  export async function fetchProvinces() {
    return Axios.get(generateUrl("locations/province"), {
      headers: await generateHeader(),
    });
  }

  export type lookups =
    | "categories"
    | "options"
    | "extras"
    | "orgTypes"
    | "provinces";
  export function fetchLookups(lookupType: lookups) {
    switch (lookupType) {
      case "categories": {
        return getCategories();
      }
      case "extras": {
        return getExtras();
      }
      case "options": {
        return getOptions();
      }
      case "orgTypes": {
        return fetchOrgTypes();
      }
      case "provinces": {
        return fetchProvinces();
      }
    }
  }
}

namespace CategoriesUrls {
  export const fetchCategoriesUrl = "product/tag/organisation";
  export const fetchOptionsUrl = "product/options/set";
  export const fetchExtrasUrl = "product/extras/set";
}
