<template>
  <S2SForm :title="isNew ? 'Create New Product' : 'Update Product'">
    <v-stepper v-model="step_position" alt-labels>
      <v-stepper-header>
        <v-stepper-step
          :complete="step_position > 1"
          step="1"
          :color="step_position > 1 ? 'success' : 'accent'"
          >Product Description</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step_position > 2"
          step="2"
          :color="step_position > 2 ? 'success' : 'accent'"
          >Product Options</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step_position > 3"
          step="3"
          :color="step_position > 3 ? 'success' : 'accent'"
          >Product Image</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <!-- Product Info -->
        <v-stepper-content step="1">
          <v-form ref="productForm" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.name"
                  label="Product Name"
                  name="name"
                  :rules="[(v) => !!v || 'Name field is required!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.shortDescription"
                  label="Short Description"
                  name="shortDescription"
                  :rules="[
                    (v) => !!v || 'Short Description field is required!',
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-textarea
                  v-model="productModel.description"
                  label="Long Description"
                  name="description"
                  auto-grow
                  rows="2"
                  :rules="[(v) => !!v || 'Long Description field is required!']"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.price"
                  label="Price"
                  name="price"
                  :rules="[
                    (v) => !!v || 'Price field is required!',
                    (v) => /^\d+(\.\d+)?$/.test(v) || 'Price format incorrect.',
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.sku"
                  label="SKU"
                  name="sku"
                  :rules="[(v) => !!v || 'SKU field is required!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.brandName"
                  label="Brand Name"
                  name="brandName"
                  :rules="[(v) => !!v || 'Brand Name field is required!']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  label="Categories"
                  :items="categories"
                  item-text="name"
                  v-model="productModel.categories"
                  name="categories"
                  multiple
                  return-object
                  chips
                  small-chips
                  deletable-chips
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  label="Labels"
                  :items="labels"
                  item-text="name"
                  v-model="productModel.labels"
                  name="labels"
                  multiple
                  return-object
                  chips
                  small-chips
                  deletable-chips
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="productModel.position"
                  label="Position"
                  name="position"
                  :rules="[(v) => !!v || 'Position field is required!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="productModel.available"
                  label="In Stock"
                  name="available"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="productModel.availableOnline"
                  label="Available on App"
                  name="availableOnline"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="productModel.enabled"
                  label="Active"
                  name="enabled"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>

          <div v-if="loading" class="text-center pa-5">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <v-divider></v-divider>
          <div class="btn-wrapper pt-3 pb-1 text-right">
            <v-btn
              :to="{ name: 'products' }"
              text
              class="mr-2"
              :disabled="loading"
              >Cancel</v-btn
            >

            <v-btn
              v-if="isNew"
              color="accent"
              @click="saveContinue(2)"
              :disabled="loading"
            >
              Save &amp; Continue
            </v-btn>

            <v-btn
              v-else
              color="accent"
              @click="updateContinue(2)"
              :disabled="loading"
            >
              Update &amp; Continue
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Product Options -->
        <v-stepper-content step="2">
          <!-- EDIT -->
          <v-container v-if="createdOptionSetId > 0 && optionsFetched.length">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="optionSet.name"
                  disabled
                  label="Option Set Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <!-- <v-text-field v-model="optionSet.description" label="Option Set Description"></v-text-field> -->
              </v-col>
            </v-row>
            <v-data-table
              :items="optionsFetched"
              :headers="[
                { text: 'Pack Size', value: 'name' },
                { text: 'Price', value: 'price' },
                { text: 'SKU', value: 'sku' },
                {
                  text: 'Active',
                  value: 'active',
                  sortable: false,
                  width: '80px',
                },
              ]"
            >
              <template v-slot:item.name="props">
                <v-edit-dialog
                  persistent
                  :return-value.sync="props.item.name"
                  @save="save(props.item, 'optionSetItem')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                  large
                >
                  {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.name"
                      :rules="[(v) => !!v || 'Pack Size field is required!']"
                      label="Pack Size"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.price="props">
                <v-edit-dialog
                  persistent
                  :return-value.sync="props.item.price"
                  @save="save(props.item, 'optionSetItem')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                  large
                >
                  {{ formatCurrency(props.item.price) }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.price"
                      :rules="[
                        (v) => !!v || 'Price field is required!',
                        (v) =>
                          /^\d+(\.)?\d+$/.test(v) || 'Price format incorrect.',
                      ]"
                      label="price"
                      single-line
                      counter
                      hint="format: 100.50"
                      persistent-hint
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.sku="props">
                <v-edit-dialog
                  persistent
                  :return-value.sync="props.item.sku"
                  @save="save(props.item, 'optionSetItem')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                  large
                >
                  {{ props.item.sku }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.sku"
                      :rules="[(v) => !!v || 'SKU field is required!']"
                      label="sku"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.active="{ item }">
                <v-btn icon @click="disableOption({ item })">
                  <v-icon color="accent" v-if="item.enabled" medium
                    >mdi-checkbox-marked</v-icon
                  >
                  <v-icon v-else-if="!item.enabled" medium
                    >mdi-close-box</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
            <v-row>
              <v-col cols="12"> Add new option </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="optionSetItem.name"
                  label="Pack Size"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="optionSetItem.price"
                  label="Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="optionSetItem.sku"
                  label="SKU"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                  @click="saveOptionItem"
                  :disabled="
                    optionSetItem.name == '' && optionSetItem.price == ''
                  "
                  color="accent"
                  >Add option</v-btn
                >
              </v-col>
            </v-row>

            <v-layout class="text-right pb-1" justify-end>
              <v-btn color="accent" @click="updateOptionSetContinue()"
                >Update &amp; Continue</v-btn
              >
            </v-layout>
          </v-container>

          <!-- NEW -->
          <v-container v-else>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="optionSet.name"
                  :disabled="!optionSetExists"
                  :error="optionSetExists"
                  label="Option Set Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <!-- <v-text-field v-model="optionSet.description" label="Option Set Description"></v-text-field> -->
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="options"
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <v-edit-dialog
                  persistent
                  :return-value.sync="item.name"
                  @save="
                    isOptionValid(item, `optionPrice-${options.indexOf(item)}`)
                  "
                  @cancel="cancel"
                  large
                  :ref="`optionName-${options.indexOf(item)}`"
                >
                  {{ item.name ? item.name : "-" }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.name"
                      :rules="[(v) => !!v || 'Pack Size field is required!']"
                      label="Pack Size"
                      single-line
                      counter
                      placeholder="Option Name"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.price="{ item }">
                <v-edit-dialog
                  persistent
                  :return-value.sync="item.price"
                  @save="
                    isOptionValid(item, `optionSKU-${options.indexOf(item)}`)
                  "
                  @cancel="cancel"
                  large
                  :ref="`optionPrice-${options.indexOf(item)}`"
                >
                  {{ item.price ? formatCurrency(item.price) : "-" }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.price"
                      :rules="[
                        (v) => !!v || 'Price field is required!',
                        (v) =>
                          /^\d+(\.\d+)?$/.test(v) || 'Price format incorrect.',
                      ]"
                      label="Price"
                      single-line
                      counter
                      placeholder="Price"
                      large
                      hint="format: 100.50"
                      persistent-hint
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.sku="{ item }">
                <v-edit-dialog
                  persistent
                  :return-value.sync="item.sku"
                  @save="validateSKU(item.sku)"
                  @cancel="cancel"
                  large
                  :ref="`optionSKU-${options.indexOf(item)}`"
                >
                  {{ item.sku ? item.sku : "-" }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.sku"
                      label="SKU"
                      single-line
                      counter
                      placeholder="SKU"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  icon
                  v-show="item.name !== '' && item.price > 0"
                  @click="clearOption(item)"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <v-layout class="text-right pb-1" justify-end>
              <v-btn text @click="addOption" class="mr-1">Add option</v-btn>
              <v-btn
                color="accent"
                @click="saveOptionsContinue()"
                :disabled="!optionsValid || loadingOption"
                >Save &amp; Continue</v-btn
              >
            </v-layout>
          </v-container>
          <div v-if="loadingOption" class="text-center pa-5">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <v-divider></v-divider>
          <div class="btn-wrapper pt-3 pb-1 text-right">
            <v-btn text @click="back(1)" :disabled="loading" class="mr-2"
              >Back</v-btn
            >
            <v-btn @click="skip(3)" color="warning" :disabled="optionsValid"
              >SKIP</v-btn
            >
          </div>
        </v-stepper-content>

        <!-- Product Image -->
        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" sm="6">
              <div v-show="imgUrl" class="container text-center">
                <div
                  class="elevation-1 pa-1 mb-2"
                  style="max-width: 120px; margin: auto"
                >
                  <img :src="imgUrl" class="preview-img" />
                </div>
                <v-btn @click="retryUpload()" text>retry</v-btn>
              </div>
              <div
                v-show="!imgUrl && !loading"
                class="container text-center pb-3"
              >
                <v-btn
                  @click="onImageUpload(createdProductId)"
                  class="upload-btn"
                >
                  <div class="btn-image">
                    <v-icon color="grey">cloud_upload</v-icon>
                    <input
                      class="d-none"
                      :ref="`image-${createdProductId}`"
                      type="file"
                      v-on:change="imageSelected"
                      :data-product-id="createdProductId"
                      accept="image/png, image/jpeg"
                    />
                  </div>
                  <div class="btn-text">Upload Image</div>
                </v-btn>
              </div>
              <div v-if="loading" class="text-center pa-5">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card color="#1a2e46" class="text-center pb-1">
                <v-card-title class="headline grey lighten-2 text-center">
                  Product Preview
                </v-card-title>

                <div class="product-preview">
                  <div class="header">
                    <div class="product-title">{{ selectedProduct.name }}</div>
                    <div class="sub-title">
                      {{ selectedProduct.shortDescription }}
                    </div>
                  </div>
                  <div class="image">
                    <div class="tags" v-if="hasLabel">
                      {{ hasLabel.name }}
                    </div>
                    <img v-if="imgUrl" :src="imgUrl" />
                    <div class="discount" v-if="selectedProduct.discount">
                      {{ selectedProduct.discount }}%
                    </div>
                  </div>
                  <div class="footer pa-1">
                    {{ hasOptions }} {{ getCheapestOption() }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <div class="btn-wrapper pt-3 pb-1 text-right">
            <v-btn text @click="back(2)" :disabled="loading" class="mr-2"
              >Back</v-btn
            >
            <v-btn color="accent" @click="saveExit()" :disabled="loading">
              Save &amp; Exit
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Lookups } from "@/api/lookups";
  import { Utils } from "@/utils/";
  export default Vue.extend({
    name: "ProductCreate",

    data: function () {
      let imgUrl = null;
      let imgFile = new FormData();
      let options = [{ name: "", price: "", sku: "" }];

      let fetchLookups = Lookups.fetchLookups;

      return {
        productModel: {
          name: "",
          shortDescription: "",
          description: "",
          price: "",
          sku: "",
          brandName: "",
          categories: [],
          labels: [],
          optionSets: [],
          position: 1,
          available: false,
          availableOnline: false,
          enabled: false,
        },
        categories: [],
        labels: [],

        fetchLookups,

        step_position: 1,
        isNew: true,

        imgUrl,
        imgFile,

        headers: [
          { text: "Pack Size", value: "name" },
          { text: "Price", value: "price" },
          { text: "SKU", value: "sku" },
          { text: "", value: "action" },
        ],

        createdOptionSetId: 0,
        optionSetExists: false,
        optionSet: {
          name: "",
          displayName: "",
          description: "",
        },
        optionSetItem: {
          name: "",
          price: "",
          sku: "",
        },
        options,
        optionsValid: false,
        optionCount: 0,

        dialog: false,
        optionId: 0,
        updateSuccess: true,

        hasLabel: null,
        hasOptions: "",

        snack: false,
        snackColor: "",
        snackText: "",
      };
    },

    computed: {
      // products
      loading: function () {
        return this.$store.state.products.loading;
      },
      selectedProduct: function () {
        return this.$store.state.products.selectedProduct;
      },
      createdProductId: function () {
        return this.$store.state.products.createdProductId;
      },

      // options
      loadingOption: function () {
        return this.$store.state.options.loading;
      },
      optionsFetched: function () {
        return this.$store.state.options.options;
      },
    },

    watch: {
      createdProductId: {
        deep: true,
        handler(newVal, oldVal) {
          this.isNew = false;
          this.optionSet.name = this.productModel.name;
          this.step_position = 2;

          // **NB get product here
          this.$store.dispatch(
            "products/getProductById",
            this.createdProductId
          );
        },
      },
      selectedProduct: function () {
        this.checkTags(this.selectedProduct.tags);
      },
    },

    methods: {
      getCategories: async function () {
        const response = await Lookups.fetchLookups("categories");
        this.categories = response.data.results.filter((val) => {
          return val.tagType.name == "menu";
        });
        this.labels = response.data.results.filter((val) => {
          return val.tagType.name == "label";
        });
      },
      onProductLoaded() {
        this.checkTags(this.selectedProduct.tags);
      },
      skip(step) {
        this.step_position = step;
      },
      async saveContinue(step) {
        const productForm = this.$refs.productForm;
        let allOK = await productForm.validate();
        if (allOK) {
          try {
            if (this.productModel.categories && this.productModel.labels) {
              this.productModel.tags = this.productModel.categories.concat(
                this.productModel.labels
              );

              // delete this field as api does not support it
              delete this.productModel.categories;
              delete this.productModel.labels;
            }

            const result = this.$store.dispatch(
              "products/createProduct",
              this.productModel
            );
          } catch (error) {
            // Placeholder, we dont need anything here
          }
        }
      },
      isOptionValid(item, moveNext) {
        if (item.name !== "" && item.price !== "") {
          this.optionsValid = true;
        } else {
          this.optionsValid = false;
        }

        if (moveNext) this.$refs[moveNext].isActive = true;
      },
      validateSKU(sku) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].sku === sku && sku !== "") {
            ++this.optionCount;
            if (this.optionCount > 1) {
              this.optionsValid = false;
              this.snack = true;
              this.snackColor = "error";
              this.snackText = "Duplicate SKU: " + sku;
              return;
            }
          } else {
            this.optionCount = 0;
          }
        }
      },
      addOption() {
        // add empty option
        let lastItem = this.options.length;
        this.options.splice(lastItem, 0, { name: "", price: "", sku: "" });

        this.$nextTick(function () {
          const optionName = `optionName-${this.options.length - 1}`;
          this.$refs[optionName].isActive = true;
        });
      },
      clearOption(item) {
        let position = this.options
          .map(function (option) {
            return option.name;
          })
          .indexOf(item.name);

        this.options.splice(position, 1);

        if (this.options.length < 1) {
          this.optionsValid = false;
        }
      },
      async saveOptionsContinue() {
        // First create option set
        this.optionSet.displayName = this.optionSet.name;

        this.createdOptionSetId = await this.$store.dispatch(
          "options/createOptionSet",
          this.optionSet
        );

        if (this.createdOptionSetId) {
          // Second add options to option set
          let cleanOptions = this.options.filter(function (item) {
            let validateItem =
              item.name !== "" && item.price >= 0 && item.price !== "";
            return validateItem;
          });

          cleanOptions.forEach((option, index) => {
            const optionItemObj = {
              name: option.name,
              price: option.price.replace(/[a-z]/gi, ""),
              sku: option.sku,
              setId: this.createdOptionSetId,
            };

            //  create option item
            if (cleanOptions.length - 1 !== index) {
              this.$store.dispatch("options/createOptionSetItem", {
                optionItemData: optionItemObj,
                notify: false,
              });
            } else {
              this.$store.dispatch("options/createOptionSetItem", {
                optionItemData: optionItemObj,
                notify: true,
              });
            }
          });
          // Finally add option set to product

          await this.linkOptionSet();
        } else {
          this.optionSetExists = true;
          /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
          console.error("Could not add items to set");
        }
      },
      async linkOptionSet() {
        await this.$store.dispatch("options/linkOptionSetToProduct", {
          productId: this.createdProductId,
          optionSetId: +this.createdOptionSetId,
        });
        // **NB get product here

        await this.$store.dispatch(
          "products/getProductById",
          this.createdProductId
        );
        this.step_position = 3;
      },
      addOptionToSet(option) {
        const optionItemObj = {
          name: option.name,
          price: option.price.replace(/[a-z]/gi, ""),
          sku: option.sku,
          setId: this.createdOptionSetId,
        };

        this.$store.dispatch("options/createOptionSetItem", {
          optionItemData: optionItemObj,
          notify: true,
          reload: true,
        });
      },
      saveOptionItem() {
        this.addOptionToSet(this.optionSetItem);
        // CLEAR FORM
        this.optionSetItem = {
          name: "",
          price: "",
          sku: "",
        };
      },
      async updateOptionSetContinue() {
        this.$store.dispatch("options/updateOptionSet", {
          optionSet: {
            name: this.optionSet.name,
            displayName: this.optionSet.displayName,
            description: this.optionSet.description,
          },
          optionSetId: this.createdOptionSetId,
        });

        // **NB get product here
        await this.$store.dispatch(
          "products/getProductById",
          this.createdProductId
        );

        this.step_position = 3;
      },
      save(option) {
        if (option.name == "" || option.description == "") {
          this.updateSuccess = false;
          return;
        }

        this.$store.dispatch("options/updateOptionSetItem", {
          optionItem: {
            name: option.name,
            price: option.price,
            sku: option.sku,
            id: option.id,
          },
          optionSetId: this.createdOptionSetId,
        });
      },
      open() {},
      cancel(option) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },
      close() {
        if (!this.updateSuccess) {
          this.$store.dispatch(
            "products/fetchOptionsById",
            this.createdOptionSetId
          );
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Data could not be saved";
          this.updateSuccess = !this.updateSuccess;
        }
      },
      disableOption(item) {
        this.$store.dispatch("options/disableOptionSetItem", {
          optionItem: item,
          optionSetId: this.createdOptionSetId,
        });
      },
      async saveExit() {
        if (this.imgFile) {
          try {
            let formData = this.imgFile;

            const success = await this.$store.dispatch(
              "products/addProductImage",
              { productId: this.createdProductId, formData }
            );
            if (success) {
              setTimeout(() => {
                this.$router.push({ name: "products" });
              }, 1000);
            }
          } catch (error) {
            // Placeholder, we dont need anything here
          }
        } else {
          this.$router.push({ name: "products" });
        }
      },
      async updateContinue(step) {
        const productForm = this.$refs.productForm;
        let allOK = await productForm.validate();
        if (allOK) {
          try {
            this.productModel.id = this.createdProductId;

            if (this.productModel.categories && this.productModel.labels) {
              this.productModel.tags = this.productModel.categories.concat(
                this.productModel.labels
              );

              // delete this field as api does not support it
              delete this.productModel.categories;
              delete this.productModel.labels;
            }

            const success = await this.$store.dispatch(
              "products/productUpdate",
              { body: this.productModel, inline: false }
            );
            if (success) this.step_position = step;

            // **NB get product here
            this.$store.dispatch(
              "products/getProductById",
              this.createdProductId
            );
          } catch (error) {
            // Placeholder, we dont need anything here
          }
        }
      },
      back(step) {
        this.step_position = step;
        if (step === 1) {
          this.productModel.categories = this.productModel.tags.filter(
            (val) => {
              return val.tagType.name == "menu";
            }
          );
          this.productModel.labels = this.productModel.tags.filter((val) => {
            return val.tagType.name == "label";
          });
        }
      },
      onImageUpload(productId) {
        this.$refs[`image-${this.createdProductId}`].click();
      },
      async imageSelected(e) {
        if (e.target.files[0]) {
          const formData = new FormData();
          formData.append("image", e.target.files[0]);
          this.imgFile = formData;

          const fr = new FileReader();
          fr.readAsDataURL(e.target.files[0]);
          fr.onload = () => {
            this.imgUrl = fr.result;
          };
        }
      },
      retryUpload() {
        this.onImageUpload(this.createdProductId);
      },
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
      getCheapestOption() {
        let cheapestOption = -1;
        if (this.selectedProduct) {
          if (
            this.selectedProduct.optionSets &&
            this.selectedProduct.optionSets.length
          ) {
            this.selectedProduct.optionSets.forEach((optionSet) => {
              if (optionSet.options && optionSet.options.length) {
                optionSet.options.forEach((option) => {
                  if (cheapestOption == -1 || option.price < cheapestOption) {
                    cheapestOption = option.price == null ? 0 : option.price;
                    this.hasOptions = "From";
                  }
                });
              } else {
                this.hasOptions = "";
              }
            });
          } else {
            this.hasOptions = "";
          }

          const productPrice = this.selectedProduct.price + cheapestOption;
          return this.formatCurrency(`${productPrice}`);
        }
      },
      checkTags(tags) {
        this.hasLabel = null;
        if (tags && tags.length) {
          tags.forEach((tag) => {
            // only select the 1st one
            if (tag.tagType.name == "label" && !this.hasLabel) {
              this.hasLabel = tag;
            }
          });
        }
      },
    },

    mounted: async function () {
      this.getCategories();
    },
  });
</script>

<style>
  .v-stepper__label {
    text-align: center;
    white-space: nowrap;
  }

  .upload-btn {
    height: 100% !important;
    text-align: center;
    padding: 15px !important;
  }
  .upload-btn span {
    display: block !important;
  }

  .btn-image {
    width: 60px;
    padding-bottom: 15px;
    margin: 0 auto;
    display: block;
  }

  .preview-img {
    width: 100%;
    max-width: 100px;
    height: auto;
  }

  .btn-text {
    display: block;
  }

  /* Product preview */
  .headline.text-center {
    justify-content: center;
  }

  .product-preview {
    width: 166px;
    margin: 15px auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .product-preview .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #c3c5c9;
    font-size: 12px;
    color: #33404d;
    text-align: center;
  }
  .product-preview .header .product-title {
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    line-height: 1;
  }
  .product-preview .header .sub-title {
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-preview .image {
    position: relative;
    height: 166px;
    background-color: #fff;
    overflow: hidden;
  }

  .product-preview .image img {
    width: 100%;
    padding: 2px;
    height: auto;
    margin: auto;
  }

  .product-preview .image .tags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    background-color: rgb(37, 138, 255);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 4px;
  }

  .product-preview .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    background-color: #697785;
  }
</style>
